<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">

    <div v-if="curLoading == reloadEnum.all || projectData == null || projectData.project == null">
      <v-row style="margin:0px;display: flex;justify-content: space-between;">
        <span style="display: flex;">
          <v-skeleton-loader class="ml-5 mt-5" type="button" max-height="50px" max-width="100px"
            style="border-radius: 100px;"></v-skeleton-loader>
          <v-skeleton-loader class="ml-5 mt-5" type="button" max-height="50px" max-width="100px"
            style="border-radius: 100px;"></v-skeleton-loader>
        </span>
        <span style="display: flex;" class="mr-5">
          <v-skeleton-loader class="ml-5 mt-8" width="100px" type="text"></v-skeleton-loader>
          <v-skeleton-loader class="ml-5 mt-5" type="button" max-height="38px" max-width="38px"
            style="border-radius: 100px;"></v-skeleton-loader>
          <v-skeleton-loader class="ml-5 mt-5" type="button" max-height="38px" max-width="38px"
            style="border-radius: 100px;"></v-skeleton-loader>
        </span>
      </v-row>
      <v-row width="100%">
        <v-skeleton-loader style="margin: auto !important" class="ml-5 mt-5"
          type="card-heading, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
          max-width="75%" min-width="75%"></v-skeleton-loader>
      </v-row>
      <v-row
        style="right: 25%;padding-top: 3%;display: flex;flex-direction: row-reverse;margin-right: 25%;margin-bottom: 10px;">
        <v-skeleton-loader type="button"></v-skeleton-loader>
      </v-row>
      <v-skeleton-loader style="margin: auto !important" class="ml-0" type="article" max-width="60%"
        min-width="60%"></v-skeleton-loader>
      <v-row class="pt-10">
        <v-skeleton-loader style="margin: auto !important" class="ml-0"
          type="card-heading, list-item-three-line, list-item-three-line, list-item-three-line" max-width="45%"
          min-width="45%"></v-skeleton-loader>
        <v-skeleton-loader style="margin: auto !important" class="ml-0"
          type="card-heading, list-item-three-line, list-item-three-line, list-item-three-line" max-width="45%"
          min-width="45%"></v-skeleton-loader>
      </v-row>
    </div>
    <div v-else>
      <v-row style="margin:0px;">
        <Breadcrumbs style="display:inline-block;" />
        <v-spacer />
        <div v-if="projectData != null && projectData.project != null" style="padding:5px;">
          <div :class="{ 'align-right': !isMobile }">
            <div class="mt-5 mr-13" style="display:inline-flex; justify-content: flex-end;">
              <v-select
                v-model="projectData.project.statusEnum"
                :disabled="readonly"
                style="max-width: 20%; width: 20%; min-width: 200px;"
                @change="updateStatusProject($event)"
                :label="$t('project.status')"
                :items="statusEnumItems"
                item-text="name"
                item-value="id"
                class='status-color'
                hide-details
                dense
              >
                <template #item="{item}">
                  <span :style="{'color': item.color.text}">{{item.name}}</span>
                </template>
              </v-select>
              <v-select v-if="projectData.project.statusEnum == PROJECT_STATUS.CANCELED || projectData.project.statusEnum == PROJECT_STATUS.DELETED"
                class="ml-10"
                style="max-width: 500px; min-width: 500px;"
                v-model="projectData.project.cancelReasonEnum"
                :disabled="readonly"
                @change="v => valueChanged('cancelReasonEnum', v)"
                :label="$t('project.cancel_reason_label')"
                :items="projectCancelReasons"
                item-text="value"
                item-value="id"
                hide-details
                dense
              >
              </v-select>
              <v-btn class="techno-green mr-5 ml-5" fab v-if="projectData.project.statusEnum == PROJECT_STATUS.CANCELED && userFunctions.find(uf => uf.id == FUNCTIONS.PROJECT_HARD_DELETE)" v-on:click="confirmHardDelete()" :title="$t('reportInfo.delete_project')">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-row>

      <v-container :fluid="isMobile">
        <v-card class="border10 mx-3 my-3">
          <v-container fluid>
            <v-row>
              <v-col xl="5" md="6" cols="12">
                <h3>{{ $t('reportInfo.dealername') }}</h3>
                <v-select dense :disabled="readonly" class="required mb-2" :items="projectData.dealers" item-text="name"
                  item-value="id" v-model="projectData.project.dealerId"
                  @change="valueChanged('dealerId', projectData.project.dealerId)">
                </v-select>

                <!-- CONTACT DETAILS -->
                <div>
                  <h3 style="margin-bottom:10px;"
                    :class="{ 'errored': projectData.project.errorCustomerAddress || projectData.project.errorCustomerName }"
                    class="required">{{ $t("reportInfo.clientinfo") }}</h3>
                  <v-autocomplete dense :disabled="readonly" :class="{ 'errored': projectData.project.errorCustomerId }"
                    :items="projectData.project.dealer.customers" :label="$t('reportInfo.client')"
                    :item-text="item => item.formattedName ?? (item.company ? item.company + ' - ' : '') + (item.firstname ?? '') + ' ' + (item.lastname ?? '')"
                    item-value="id" v-model="projectData.project.customerId" @change="v => valueChanged('customerId', v)"
                    v-if="projectData.project.dealer">
                  </v-autocomplete>
                  <v-row :disabled="!canManageCustomers">
                    <v-col cols="6">
                      <v-text-field
                        :class="{ 'errored': projectData.project.errorCustomerFirstname, 'required': projectData.project.customerFirstname || (!projectData.project.customerCompany && !projectData.project.customerLastname) }"
                        dense :value="projectData.project.customerFirstname"
                        @change="v => valueChanged('customerFirstname', v)" :label="$t('reportInfo.firstname')"
                        @keyup="v => valueChanged('customerFirstname', v.target.value, true)"
                        maxlength="45" hide-details :disabled="readonly">
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field dense
                        :class="{ 'errored': projectData.project.errorCustomerLastname, 'required': projectData.project.customerLastname || (!projectData.project.customerCompany && !projectData.project.customerFirstname) }"
                        :value="projectData.project.customerLastname" @change="v => valueChanged('customerLastname', v)"
                        @keyup="v => valueChanged('customerLastname', v.target.value, true)"
                        :label="$t('reportInfo.lastname')" maxlength="45" hide-details :disabled="readonly">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field :label="$t('reportInfo.company')" dense
                        :class="{ 'errored': projectData.project.errorCustomerCompany, 'required': projectData.project.customerCompany || (!projectData.project.customerFirstname && !projectData.project.customerLastname) }"
                        :value="projectData.project.customerCompany" @change="v => valueChanged('customerCompany', v)"
                        @keyup="v => valueChanged('customerCompany', v.target.value, true)"
                        maxlength="255" hide-details :disabled="readonly"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field dense :value="projectData.project.customerPhoneMobile"
                        @change="v => valueChanged('customerPhoneMobile', v)" :disabled="readonly" maxlength="50"
                        hide-details :label="$t('user.phone_mobile')"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-form ref="emailForm1">
                        <v-text-field dense :value="projectData.project.customerEmailMain" :rules="emailRules"
                          :label="$t('user.main_email')" hide-details :disabled="readonly" maxlength="320"
                          @change="v => valueChanged('customerEmailMain', v)"></v-text-field>
                      </v-form>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <AddressField class='address-field' @saveAddress="saveCustomerAddress" @onShowMap="onShowMap"
                        :address="projectData.project.customerAddress" ref="customerAddressRef" :disabled="readonly"
                        :country="projectData.project.dealer.placeAutocompleteRestricted?.split(',')"
                        :mapDisabled="projectData.project.mapDisabled"
                        :showMarker="projectData.project.isCustomerAddress && curLoading != reloadEnum.address"
                        :errorAddress1="projectData.project.errorCustomerAddress1"
                        :errorCity="projectData.project.errorCustomerCity"
                        :errorCountry="projectData.project.errorCustomerCountry"
                        :errorState="projectData.project.errorCustomerState"
                        :errorPoBox="projectData.project.errorCustomerPoBox"
                        :key="projectData.project.customerAddress.id"
                        :id="'autocompleteCustomer' + projectData.project.customerId" />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-select v-if="projectData.project.dealer.displayCustomerCategory" dense
                        :items="[{ name: '', id: null },
                        { name: $t('customer.home_owner'), id: CUSTOMER_CATEGORY.HOME_OWNER },
                        { name: $t('customer.residential_company'), id: CUSTOMER_CATEGORY.RESIDENTIAL_COMPANY },
                        { name: $t('customer.commercial_company'), id: CUSTOMER_CATEGORY.COMMERCIAL_COMPANY }]" :label="$t('customer.category')" item-text="name" item-value="id"
                        :disabled="readonly" v-model="projectData.project.customerCategory"
                        @change="v => valueChanged('customerCategory', v)"></v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="!(projectData.project.customerId > 0) && !readonly">
                    <v-col cols="12" style="text-align:center;">
                      <v-btn tile color="#009f4d" style="color:white;margin-top:10px;" v-on:click="transformCustomer()"
                        :loading="transformingCustomer"
                        v-if="userFunctions.find(uf => uf.id == FUNCTIONS.TRANSFORM_TEMP_CUSTOMER)">
                        {{ $t("reportInfo.client_official") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <!-- END CONTACT DETAILS -->
              </v-col>
              <v-col xl="5" md="6" cols="12">
                <!-- SITE ADDRESS -->
                <h3 :class="{ 'errored': projectData.project.errorSiteAddress }" class="required mt-0"
                  style="margin-top:15px;">{{ $t("reportInfo.site_address") }}</h3>
                <v-checkbox v-model="projectData.project.isCustomerAddress" :label="$t('reportInfo.sameprojectaddress')"
                  :disabled="readonly" @change="v => valueChanged('isCustomerAddress', v)">
                </v-checkbox>
                <AddressField v-if="!projectData.project.isCustomerAddress" class='address-field'
                  @saveAddress="saveProjectAddress" @onShowMap="onShowMap" :address="projectData.project.siteAddress"
                  :country="projectData.project.dealer.placeAutocompleteRestricted?.split(',')"
                  ref="projectAddressRef" :disabled="readonly" :mapDisabled="projectData.project.mapDisabled"
                  :showMarker="!projectData.project.isCustomerAddress && curLoading != reloadEnum.address"
                  :errorAddress1="projectData.project.siteAddress.errorAddress1"
                  :errorCity="projectData.project.siteAddress.errorCity"
                  :errorCountry="projectData.project.siteAddress.errorCountry"
                  :errorState="projectData.project.siteAddress.errorState"
                  :errorPoBox="projectData.project.siteAddress.errorPoBox" :key="projectData.project.siteAddress.id"
                  :id="'autocompleteSite' + projectData.project.addressId" />
                <!-- END SITE ADDRESS -->
              </v-col>
              <v-col xl="2" md="6" cols="12">
                <strong>{{ $t('reportInfo.projectinfo') }}</strong>
                <v-text-field :label="$t('reportInfo.referenceNumber')" :value="projectData.project.referenceNumber ?? ''"
                  @change="v => valueChanged('referenceNumber', v)" maxlength="45" hide-details >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-row class="mx-1 my-1">
          <v-col sm="8" cols="12" style="margin:auto;">
            <v-row justify="end">
              <v-col align="right" class="pb-0">
                <v-menu>
                  <template v-if="!readonly" v-slot:activator="{ on, attrs }">
                    <v-btn :title="$t('project.new')" class="techno-green" v-bind="attrs" v-on="on" :disabled="projectData.project.statusEnum == PROJECT_STATUS.CLOSED || projectData.project.statusEnum == PROJECT_STATUS.CANCELED || projectData.project.statusEnum == PROJECT_STATUS.DELETED">
                      <v-icon style="color:white;">mdi-file-document</v-icon>
                      {{ $t('project.new') }}
                    </v-btn>
                  </template>
                  <v-list v-if="engineeringDepartment">
                    <v-list-item @click.prevent="createRequest(REQUEST_TYPES.FIELD_REPORT)">
                      {{ $t('documentRequests.dr_fieldreport') }}
                    </v-list-item>
                    <v-list-item v-if="engineeringDepartment.recommendationRequestInfo" @click.prevent="createRequest(REQUEST_TYPES.RECOMMENDATION_REQUEST)">
                      {{ $t('documentRequests.dr_recommendation') }}
                    </v-list-item>
                    <v-list-item v-if="engineeringDepartment.permitRequestInfo" @click.prevent="createRequest(REQUEST_TYPES.PERMIT_REQUEST)">
                      {{ $t('documentRequests.dr_permit') }}
                    </v-list-item>
                    <v-list-item v-if="engineeringDepartment.showTestInstallationField" @click.prevent="createRequest(REQUEST_TYPES.TEST_INSTALLATION_FIELD)">
                      {{ $t('documentRequests.dr_testinstallationfieldnote') }}
                    </v-list-item>

                    <v-tooltip bottom :disabled="stampedFieldReportTooltip == null">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" style="width:100%;">

                          <v-list-item v-if="engineeringDepartment.stampedFieldReportInfo" 
                                       :disabled="stampedFieldReportTooltip != null" 
                                       v-bind="attrs" v-on="on" 
                                       @click.prevent="createRequest(REQUEST_TYPES.FIELD_REPORT, DOCUMENT_REQUEST_TYPES.STAMPED_FIELD_REPORT)">
                            {{ $t('documentRequests.dr_stampedfieldreport') }}
                          </v-list-item>
                        </div>
                      </template>
                      <span>{{ stampedFieldReportTooltip }}</span>
                    </v-tooltip>

                    <v-tooltip bottom :disabled="attestationRequestTooltip == null">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" style="width:100%;">

                          <v-list-item v-if="engineeringDepartment.conformityReportInfo" 
                                       :disabled="attestationRequestTooltip != null" 
                                       v-bind="attrs" v-on="on" 
                                       @click.prevent="createRequest(REQUEST_TYPES.FIELD_REPORT, DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST)">
                            {{ engineeringDepartment.conformityReportAsDoe ? $t('documentRequests.dr_doe') : $t('documentRequests.dr_attestation') }}
                          </v-list-item>
                        </div>
                      </template>
                      <span>{{ attestationRequestTooltip }}</span>
                    </v-tooltip>

                    <v-tooltip bottom :disabled="buildingInspectionFormTooltip == null">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" style="width:100%;">

                          <v-list-item v-if="engineeringDepartment.buildingInspectionFormInfo" 
                                       :disabled="buildingInspectionFormTooltip != null" 
                                       v-bind="attrs" v-on="on" 
                                       @click.prevent="createRequest(REQUEST_TYPES.FIELD_REPORT, DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM)">
                            {{ $t('documentRequests.dr_buildinginspectionform') }}
                          </v-list-item>
                        </div>
                      </template>
                      <span>{{ buildingInspectionFormTooltip }}</span>
                    </v-tooltip>

                    <v-tooltip bottom :disabled="tproRequestTooltip == null">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" style="width:100%;">

                          <v-list-item v-if="engineeringDepartment.tproInfo" 
                                       :disabled="tproRequestTooltip != null" 
                                       v-bind="attrs" v-on="on" 
                                       @click.prevent="createRequest(REQUEST_TYPES.FIELD_REPORT, DOCUMENT_REQUEST_TYPES.T_PRO)">
                            {{ $t('documentRequests.dr_tpro') }}
                          </v-list-item>
                        </div>
                      </template>
                      <span>{{ tproRequestTooltip }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="border10 align-center">
                  <v-list v-if="projectData != null">
                    <v-subheader>
                      <v-list-item-content><h3 style="color:rgba(0, 0, 0, 0.87);">{{ $t('project.documents') }}</h3></v-list-item-content>
                      <v-list-item-content><h3 style="color:rgba(0, 0, 0, 0.87);">{{ $t('project.status') }}</h3></v-list-item-content>
                    </v-subheader>
                    <v-list-item v-for="doc of projectData.documents" v-bind:key="doc.id">
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="!doc.siteReportId">{{ $t(REQUEST_TYPES_TEXT[doc.requestType]) }} #{{ doc.id }}</v-list-item-title>
                        <v-list-item-title v-else
                          :class="doc.mark ? 'request' : ''">{{ (doc.documentTypeEnum == DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST && projectData.project.dealer.engineering_department.conformityReportAsDoe?$t('documentRequests.dr_doe'):$t(DOCUMENT_REQUEST_TYPES_TEXT[doc.documentTypeEnum])) }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title
                          v-if="!doc.siteReportId"><v-chip :style="'color:' + SITE_REPORT_STATUS_COLOR[doc.statusEnum].inverted + ';background-color:' + SITE_REPORT_STATUS_COLOR[doc.statusEnum].text + ';'">{{ $t(SITE_REPORT_STATUS_TEXT[doc.statusEnum]) }}</v-chip></v-list-item-title>
                        <v-list-item-title
                          v-else><v-chip :style="'color:' + DOCUMENT_REQUEST_STATUS_COLOR[doc.statusEnum].inverted + ';background-color:' + DOCUMENT_REQUEST_STATUS_COLOR[doc.statusEnum].text + ';'">{{ $t(DOCUMENT_REQUEST_STATUS_TEXT[doc.statusEnum]) }}</v-chip></v-list-item-title>
                      </v-list-item-content>

                      <div style="width: 100px;">
                        <v-list-item-icon v-if="!doc.mark" style="margin: 0 2px;">
                          <v-icon @click="gotoReport(doc.siteReportId || doc.id)" style="margin: 0 2px;">mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else style="margin: 0 2px;">
                          <v-icon style="margin: 0 14px;">mdi-does-not-exist</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-if="doc.files && doc.files.length > 0">
                          <v-progress-circular v-if="isDownloadingFile.includes(doc.id)" color="grey" indeterminate></v-progress-circular>
                          <v-icon v-else @click="downloadFile(doc.id)" :title="$t('reportInfo.download_file')">mdi-download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else style="margin: 0 2px;">
                          <v-icon style="margin: 0 14px;"></v-icon>
                        </v-list-item-icon>
                      </div>
                    </v-list-item>
                  </v-list>

                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="6" cols="12">
            <project-file-list class="mt-3" :readonly="readonly"
              :files="projectData != null && projectData.files != null ? projectData.files : []"
              :projectId="selectedProjectId" :listUpdated="() => reloadPage(reloadEnum.files)" />
          </v-col>
          <v-col md="6" cols="12">
            <comment-list :readonly="readonly" relatableType="projects" :relatableId="selectedProjectId" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="showMap">
      <MapEmbed
        :key = "mapkey"
        :lat = "projectData?.project?.latitude"
        :lng = "projectData?.project?.longitude"
        :countries = "countries"
        :fallbackAddress = "(projectData != null && projectData.project != null && projectData.project.dealer != null && projectData.project.dealer.addresses != null && projectData.project.dealer.addresses.length > 0) ? projectData.project.dealer.addresses[0] : null"
        :addressCurrentlySet = "projectData?.project?.siteAddress?.address1?.trim().length > 0"
        :close = "() => showMap = false"
        :latLngChanged = "(latLng) => {
          if (projectData.project.isCustomerAddress) saveCustomerAddress({ lat: latLng.latitude, lng: latLng.longitude });
          else saveProjectAddress({ lat: latLng.latitude, lng: latLng.longitude });
        }"
        :addressChanged = "(addr) => {
          if (projectData.project.isCustomerAddress) saveCustomerAddress(addr);
          else saveProjectAddress(addr);
        }"
        :askConfirmApplyGPS = "() => askConfirmApplyGPS()"
      ></MapEmbed>
    </v-dialog>

    <v-dialog v-model="projectChangedModal" max-width="600" persistent>
      <v-card>
        <v-card-title>
          {{ $t('reportInfo.project_changed', {name: projectChangedModalBy })}}
        </v-card-title>
        <v-card-text>
          {{ $t('reportInfo.project_changed_details') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="projectChangedModal = false; reloadPage();">{{
            $t("general.reload_page") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ confirmDialogTitle }}
        </v-card-title>
        <v-card-text>
          <v-alert v-if="confirmAction == ConfirmAction.HardDelete" type="error">{{ confirmDialogBody }}</v-alert>
          <span v-else>{{ confirmDialogBody }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmDialogNo()">{{ confirmYesNo ? $t("reportInfo.no") :
            $t("confirm.cancel") }}</v-btn>
          <v-btn color="green darken-1" text @click="confirmDialogYes()">{{ confirmYesNo ? $t("reportInfo.yes") :
            $t("confirm.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="transformDialog" v-model="transformDialog" max-width="900">
      <v-card>
        <v-card-title>
          {{ transformDialogTitle }}
        </v-card-title>
        <v-card-text>{{ transformDialogBody }}</v-card-text>
        <v-card-actions>
          <v-select dense
            :items="[{ name: $t('confirm.select_similar_client'), id: 0 }, { name: $t('confirm.save_new_client'), id: 1 }]"
            item-text="name" item-value="id" v-model="transformCustomerOption">
          </v-select>
        </v-card-actions>
        <v-card-actions>
          <v-select dense :items="similarCustomers"
            :item-text="similarCustomer => similarCustomer.formattedNameAndCompany + ' - ' + similarCustomer.formattedAddress"
            item-value="id" v-model="selectedSimilarCustomerId" v-if="transformCustomerOption == 0"
            :label="$t('confirm.update_similar_client')">
          </v-select>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="transformDialog = false;">{{ $t("confirm.cancel") }}</v-btn>
          <v-btn color="green darken-1" text @click="saveCustomerToDB()" v-if="transformCustomerOption == 1">{{
            $t("confirm.save_client") }}</v-btn>
          <v-btn color="green darken-1" text @click="updateExistingCustomer()" v-else>{{ $t("confirm.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="selectReportDialog" v-model="selectReportDialog" max-width="900">
      <v-card>
        <v-card-title>
          {{ $t('project.multiple_reports') }}
        </v-card-title>
        <v-card-text>{{ $t('project.select_report') }}</v-card-text>
        <v-card-actions>
          <v-select dense 
                    :items="availableValidatedReports"
                    :item-text="report => '#' + report.id + ' (' + report.installationStart + ' - ' + report.installationEnd + ')'"
                    item-value="id" 
                    v-model="selectedReportId" 
                    :label="$t('documentRequests.dr_fieldreport')">
          </v-select>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="selectReportDialog = false;">{{ $t("confirm.cancel") }}</v-btn>
          <v-btn color="green" style="color:white;" :disabled="selectedReportId == null" @click="selectReport()">{{ $t("confirm.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import MapEmbed from "@/components/MapEmbed";
import UserService from "@/services/user.service";
import ProjectService from "@/services/project.service";
import CustomerService from "@/services/customer.service";
import { sortByProperty, getLanguage } from "@/utils/utils";
import { REQUEST_TYPES_TEXT, DOCUMENT_REQUEST_STATUS_TEXT, SITE_REPORT_STATUS_TEXT, REQUEST_TYPES, PROJECT_STATUS, PROJECT_STATUS_TEXT, PROJECT_STATUS_COLOR, PROJECT_CANCEL_REASON, STATUS_COLOR, CUSTOMER_CATEGORY, FUNCTIONS, DOCUMENT_REQUEST_TYPES, DOCUMENT_REQUEST_TYPES_TEXT, SITE_REPORT_STATUS, DOCUMENT_REQUEST_STATUS, SITE_REPORT_STATUS_COLOR, DOCUMENT_REQUEST_STATUS_COLOR } from "@/enums.js"
import { updateCustomerFormattedName, getLongFormattedAddress } from "@/store/utility";
import ProjectFileList from "@/components/ProjectFileList";
import CommentList from "@/components/CommentList";
import AddressField from '@/components/reusableFields/AddressField';
import DealerService from "@/services/dealer.service";
import authHeader from "@/services/auth-header";
import axios from "axios";
import { parseError } from '@/utils/utils';

const ConfirmAction = Object.freeze({ ChangeGps: 3, ChangeGpsCustomer: 4, HardDelete: 5 });

export default {
  components: {
    Breadcrumbs,
    MapEmbed,
    ProjectFileList,
    CommentList,
    AddressField
  },
  watch: {
    transformDialog() {
      if (!this.transformDialog && !this.savingCustomer) {
        this.transformingCustomer = false;
      }
    }
  },
  data() {
    let statusEnumItems = [];
    for (let i in Object.fromEntries(Object.entries(PROJECT_STATUS).filter(([key]) => key !== "DELETED"))) {
      statusEnumItems.push({ 'color':PROJECT_STATUS_COLOR[PROJECT_STATUS[i]],  'id': PROJECT_STATUS[i], 'name': this.$t(PROJECT_STATUS_TEXT[PROJECT_STATUS[i]])})
    }

    return {
      oldStatusEnum:null,
      statusEnumItems:statusEnumItems,      
      selectedProjectId: null,
      projectData: null,
      curLoading: 0,
      showMap: false,
      savingData: false,
      transformingCustomer: false,
      readonly: true,
      emailRules: [
        (v) => v == null || v == '' || /.+@.+\..+/.test(v) || this.$t("customer.email_valid"),
      ],
      CUSTOMER_CATEGORY: CUSTOMER_CATEGORY,
      FUNCTIONS: FUNCTIONS,
      DOCUMENT_REQUEST_TYPES: DOCUMENT_REQUEST_TYPES,
      DOCUMENT_REQUEST_TYPES_TEXT: DOCUMENT_REQUEST_TYPES_TEXT,
      DOCUMENT_REQUEST_STATUS_TEXT: DOCUMENT_REQUEST_STATUS_TEXT,
      DOCUMENT_REQUEST_STATUS_COLOR: DOCUMENT_REQUEST_STATUS_COLOR,
      REQUEST_TYPES: REQUEST_TYPES,
      REQUEST_TYPES_TEXT: REQUEST_TYPES_TEXT,
      PROJECT_STATUS: PROJECT_STATUS,
      PROJECT_CANCEL_REASON: PROJECT_CANCEL_REASON,
      STATUS_COLOR: STATUS_COLOR,
      PROJECT_STATUS_COLOR: PROJECT_STATUS_COLOR,
      PROJECT_STATUS_TEXT: PROJECT_STATUS_TEXT,
      SITE_REPORT_STATUS_TEXT: SITE_REPORT_STATUS_TEXT,
      SITE_REPORT_STATUS_COLOR:SITE_REPORT_STATUS_COLOR,
      ConfirmAction: ConfirmAction,
      confirmDialog: false,
      confirmDialogTitle: null,
      confirmDialogBody: null,
      confirmAction: null,
      confirmYesNo: true,
      confirmPendingData: null,
      projectChangedModal: false,
      projectChangedModalBy: '',
      reloadEnum: {
        nothing: 0,
        all: 1,
        address: 2,
        files: 3
      },
      transformDialog: false,
      transformDialogTitle: null,
      transformDialogBody: null,
      transformCustomerOption: 0,
      selectedSimilarCustomerId: null,
      selectReportDialog: false,
      availableValidatedReports: [],
      selectedReportId: null,
      documentRequestType: null,
      isDownloadingFile: [],
      projectCancelReasons: [],
      promiseAskConfirm: null,
      mapkey: Date.now()
    }
  },
  async mounted() {
    for (let key in PROJECT_CANCEL_REASON) {
      this.projectCancelReasons.push({id: key, value: this.$t(`project.cancel_reason.${key}`)});
    }
    let breadCrumbs = []
    breadCrumbs.push({ text: this.$t("projects.projects"), href: "/projects" })
    breadCrumbs.push({ text: this.$t("projects.project") + " # " + this.$route.params.id })
    this.setBreadcrumbs(breadCrumbs);

    this.$socket.on('projectChanged', (data) => {
      // Show a modal that request the user to reload the report if another user modified this report
      if (this.projectData.project.id == data.projectId && this.$socket.id != data.socketId) {
        this.projectChangedModalBy = data?.byFullName || 'N/A';
        this.projectChangedModal = true;
      }
    });

    this.reloadPage();
  },
  created() {
    //console.log('Report created')
    if (this.$route.params['documentRequestIdDownload']){
      this.openToast({ message: this.$t("project.downloadInProgress"), duration: 5000, color: 'green' });
      this.downloadFile(this.$route.params.documentRequestIdDownload);
    }
  },
  updated() {
    //console.log('Report updated')
  },
  beforeDestroy() {
    this.$socket.off('projectChanged');
    //document.querySelectorAll(".pac-container").forEach(e => { console.log(e); e.remove(); });
  },
  computed: {
    ...mapGetters(['canManageCustomers']),
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      userFunctions: (state) => state.userFunctions,
      countries: (state) => state.countries,
      currentUser: state => state.currentUser
    }),
    engineeringDepartment() {
      return this.projectData.project.dealer.engineering_department;
    },
    attestationRequestTooltip() {
      return this.getRequestTooltip(DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST, 'project.attestation_request_tooltip');
    },
    buildingInspectionFormTooltip() {
      return this.getRequestTooltip(DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM, 'project.building_inspection_form_tooltip');
    },
    stampedFieldReportTooltip() {
      return this.getRequestTooltip(DOCUMENT_REQUEST_TYPES.STAMPED_FIELD_REPORT, 'project.stamped_field_report_tooltip');
    },
    tproRequestTooltip() {
      return this.getRequestTooltip(DOCUMENT_REQUEST_TYPES.T_PRO, 'project.tpro_tooltip');
    }
  },
  methods: {
    log: console.warn,
    ...mapMutations([
      'openToast',
      'showErrorMessage'
    ]),
    ...mapActions([
      'setBreadcrumbs',
    ]),
    reloadPage(forReloadEnum) {
      this.setSelectedProject(this.$route.params.id, forReloadEnum);
    },
    setSelectedProject(projectId, forReloadEnum) {
      this.resetProject();
      this.setSelectedProjectId(projectId);
      this.retrieveProjectData(forReloadEnum);
    },
    async retrieveProjectData(forReloadEnum) {
      this.curLoading = forReloadEnum || this.reloadEnum.all;
      try {
        var data = await UserService.getProjectData(this.selectedProjectId, getLanguage());
      } catch (error) {
        this.openToast({ message: parseError(error), duration: 5000, color: 'red' });
        return this.$router.push('/');
      }
      if (data && data.data) {
        this.projectData = data.data;
        await this.fetchCustomersList();
      }
      else this.projectData = {};
      this.formatSelectedReportDealer();
      this.curLoading = this.reloadEnum.nothing;
      let allowEdit = this.userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION) != null;
      let readOnly = this.userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_FROM_ENGINEERING_DEPARTMENTS_READONLY) != null;

      this.readonly = !allowEdit || readOnly;
      this.$forceUpdate();

      if (this.$refs.customerAddressRef) {
        this.$refs.customerAddressRef.updateAddress(this.projectData.project.customerAddress);
      }
      if (this.$refs.projectAddressRef) {
        this.$refs.projectAddressRef.updateAddress(this.projectData.project.siteAddress);
      }

      this.oldStatusEnum = this.projectData.project.statusEnum;
      this.applyStatusColor();
    },
    formatSelectedReportDealer() {

      let dealer = this.projectData.project.dealer;

      dealer.customers.forEach((c) => {
        updateCustomerFormattedName(c);
        c.formattedAddress = getLongFormattedAddress(this.countries, c.address);
      });
      sortByProperty(dealer.customers, 'formattedName')

      dealer.customers.splice(0, 0, {
        id: -1,
        isOther: true,
        firstname: "",
        lastname: "",
        address: {
          id: -1,
          address1: "",
          address2: "",
          city: "",
          countryId: null,
          stateId: null,
          poBox: "",
          postalCode: "",
        },
      });
      // Set the formatted name for the OTHER option
      updateCustomerFormattedName(dealer.customers[0]);

      if (!this.projectData.project.customerId) {
        this.projectData.project.customerId = -1;
      }
    },
    resetProject() {
      console.log('TODO: resetProject');
    },
    setSelectedProjectId(id) {
      this.selectedProjectId = id;
    },
    async fetchCustomersList(idDealer) {
      if (idDealer == null) idDealer = this.projectData.project.dealerId;
      if (idDealer == null) return this.projectData.project.dealer.customers = [];
      try {
        let response = await DealerService.getDealerData(idDealer, getLanguage());
        this.projectData.project.dealer.customers = response.data.customers;
        this.projectData.project.dealer.customers.forEach((c) => {
          updateCustomerFormattedName(c);
          c.formattedAddress = getLongFormattedAddress(this.countries, c.address);
        });
        sortByProperty(this.projectData.project.dealer.customers, 'formattedName')
      }
      catch (err) {
        alert(err)
        this.projectData.project.dealer.customers = [];
      }
    },
    async valueChanged(valueName, newValue, justRefresh) {
      try {
        if (justRefresh !== true) this.savingData = true;
        this.projectData.project[valueName] = newValue;
        if (justRefresh === true) return;
        await ProjectService.changeValue(this.selectedProjectId, valueName, newValue);
        if (valueName == 'dealerId') {
          this.retrieveProjectData(this.reloadEnum.all);
        }
        else if (['customerId', 'isCustomerAddress'].indexOf(valueName) >= 0) {
          this.retrieveProjectData(this.reloadEnum.address);
        }
      }
      catch(error) {
        this.retrieveProjectData(this.reloadEnum.all);

        let errorMsg = parseError(error);
        console.log(errorMsg);
        this.showErrorMessage(errorMsg);
      }
      finally {
        this.savingData = false;
      }
    },
    onShowMap() {
      this.showMap = true;
      this.mapkey = Date.now();
    },
    async downloadFile(documentRequestId) {
      this.isDownloadingFile.push(documentRequestId);
      let link = '/api/documentRequest/download/' + documentRequestId;

      try{

        let result = await axios.get(link, {
          responseType: 'arraybuffer',
            headers: authHeader()
        });
        let headers = result.headers;

        let contentType = headers[Object.keys(headers).find(k => k.toLowerCase() == 'content-type')];
        contentType = (contentType??'application/octet-stream');

        let headerLine = headers[Object.keys(headers).find(k => k.toLowerCase() == 'content-disposition')];
        let fileName = (headerLine?decodeURI(headerLine.substring(headerLine.indexOf('"') + 1, headerLine.lastIndexOf('"'))):file.bin);

        const a = document.createElement('a');
        a.download = fileName;
        let file = new Blob([result.data], {type: contentType});
        let url = URL.createObjectURL(file);        
        a.href = url;
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.remove();
          }, 100);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        
      }
      catch (error) {
        this.openToast({ message: error, duration: 15000, color: 'red' });
      }
      finally{
        this.isDownloadingFile = this.isDownloadingFile.filter(x => x !== documentRequestId);
      }
    },

    gotoReport(reportId, documentRequestType) {
      let url = `/report/${reportId}`;
      if (documentRequestType != undefined) {
        url += `?newDocumentRequestType=${documentRequestType}`;
      }
      this.$router.push(url);
    },
    async createRequest(requestType, documentRequestType) {

      if (documentRequestType != undefined) {
        this.availableValidatedReports = this.getAvailableValidatedReport(documentRequestType);

        if (this.availableValidatedReports.length == 1) {
          this.gotoReport(this.availableValidatedReports[0].id, documentRequestType);
        }
        else if (this.availableValidatedReports.length > 1) {
          this.documentRequestType = documentRequestType;
          this.selectedReportId = null;
          this.selectReportDialog = true;
        }
      }
      else {
        let result = await ProjectService.createRequest(this.selectedProjectId, requestType, getLanguage());
        let data = result.data;
        if (data != null && data.report != null && data.report.id != null) {
          this.gotoReport(data.report.id);
        }
      }
    },
    async saveCustomerAddress(addressChanges) {
      await ProjectService.changeAddress(this.projectData.project.id, this.projectData.project.customerAddressId, addressChanges);
      this.retrieveProjectData(this.reloadEnum.address);
    },
    async saveProjectAddress(addressChanges) {
      await ProjectService.changeAddress(this.projectData.project.id, this.projectData.project.addressId, addressChanges);
      this.retrieveProjectData(this.reloadEnum.address);
    },
    confirmDialogNo() {
      if (this.confirmAction == ConfirmAction.ChangeGps) this.promiseAskConfirm(false);
      this.confirmDialog = false;
    },
    confirmDialogYes() {
      switch (this.confirmAction) {
        case ConfirmAction.ChangeGps:
          this.promiseAskConfirm(true);
          break;
        case ConfirmAction.HardDelete:
          this.hardDelete();
          break;
        default:
          break;
      }
      this.confirmDialog = false;
    },

    async hardDelete() {
      try {
        this.isSaving = true;

        let response = await ProjectService.updateStatusProject(this.selectedProjectId, PROJECT_STATUS.DELETED);
        this.isSaving = false;
        if (response.status == 200) {
          this.$router.push('/projects');
        }
        else{
          this.openToast({ message: this.$t(response), duration: 15000, color: 'red' });
        }
      }
      catch (error) {
        this.isSaving = false;
        console.log(error)
        this.openToast({ message: error, duration: 15000, color: 'red' });
      }
      this.applyStatusColor();
    },

    applyStatusColor() {
      this.$nextTick(() => {
        let els = document.querySelectorAll(`.status-color .v-select__selection`);
        for (let el of els) {
          if (el != null) {
            el.style.color = this.PROJECT_STATUS_COLOR[this.projectData.project.statusEnum].text
          }
        }
      });
    },
    async updateStatusProject() {
      try {
        this.isSaving = true;

        let response = await ProjectService.updateStatusProject(this.selectedProjectId, this.projectData.project.statusEnum);
        this.isSaving = false;
        if (response.status != 200) {
          this.projectData.project.statusEnum = this.oldStatusEnum;
          this.openToast({ message: this.$t(response), duration: 15000, color: 'red' });
        }
        else{
          this.oldStatusEnum = this.projectData.project.statusEnum;
        }
      }
      catch (error) {
        this.isSaving = false;
        console.log(error)
        this.openToast({ message: error, duration: 15000, color: 'red' });
      }
      this.applyStatusColor();
    },
    async updateExistingCustomer() {
      await this.saveCustomerAddress({ updateExistingCustomer: this.selectedSimilarCustomerId });
      this.transformDialog = false;
    },
    async saveCustomerToDB() {
      await this.saveCustomerAddress({ transformCustomer: true });
      this.transformDialog = false;
    },
    async transformCustomer() {
      if (!this.projectData.project.customerCompany &&
        (!(this.projectData.project.customerFirstname && this.projectData.project.customerLastname))) {
        this.openToast({ message: this.$t("reportInfo.valid_customer"), duration: 5000, color: 'red' });
        return;
      }

      this.transformingCustomer = true;

      this.similarCustomers = await CustomerService.getReportOtherCustomerSimilarCustomers(this.projectData.project.id);
      if (this.similarCustomers.length > 0) {
        this.transformDialog = true;
        this.transformDialogTitle = this.$t('confirm.similar_client');
        this.transformDialogBody = this.$t('confirm.transform_client');
        this.selectedSimilarCustomerId = this.similarCustomers[0].id;
      }
      else {
        // Save directly since no similar clients
        await this.saveCustomerToDB();
      }
      this.transformingCustomer = false;
    },
    getAvailableValidatedReport(documentType) {
      return this.projectData.documents.filter(d => d.requestType == REQUEST_TYPES.FIELD_REPORT &&
                                                  d.statusEnum == SITE_REPORT_STATUS.VALIDATED &&
                                                  !d.document_requests.some(dr => dr.documentTypeEnum == documentType &&
                                                                                  dr.statusEnum != DOCUMENT_REQUEST_STATUS.CANCELED));
    },
    getRequestTooltip(documentType, tooltip) {
      let availableValidatedReports = this.getAvailableValidatedReport(documentType);
      if (availableValidatedReports.length == 0) {
        return this.$t(tooltip);
      }
      return null;
    },
    selectReport() {
      this.selectReportDialog = false;
      this.gotoReport(this.selectedReportId, this.documentRequestType);
    },
    askConfirmApplyGPS() {
      return new Promise(r => {
        this.promiseAskConfirm = r;
        this.confirmDialog = true;
        this.confirmDialogTitle = this.$t('confirm.head');
        this.confirmDialogBody = this.$t('confirm.change_gps');
        this.confirmAction = ConfirmAction.ChangeGps;
        this.confirmYesNo = true;
      });
    },
    confirmHardDelete() {
      return new Promise(r => {
        this.promiseAskConfirm = r;
        this.confirmDialog = true;
        this.confirmDialogTitle = this.$t('confirm.head');
        this.confirmDialogBody = this.$t('confirm.hard_delete_project_message');
        this.confirmAction = ConfirmAction.HardDelete;
        this.confirmYesNo = true;
      });
    }
  }, // -- End of methods
};
</script>

<style scoped>
.topinfo {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.topmain {
  display: flex;
  flex-direction: row;
  flex-grow: 7;
  flex-wrap: wrap;
  justify-content: space-around;
}

.topcontent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}

.topcontentblock {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.topbutton {
  display: flex;
  flex-grow: 3;
  justify-content: flex-end;
  margin: 20px;
}

.v-list-item--link:hover {
  background-color: #009f4d33;
}

.request {
  margin-left: 10px;
}

.request:before {
  content: "\27A4\0000a0";
  margin-right: 10px;
}

.required::after{
    content: "*";
}
</style>
