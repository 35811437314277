<template>
  <div id="comment-list" class="commentlist-content">
    <v-card class="border10 ml-3 mr-3 pr-5 pl-3 mt-3">
      <v-row justify="space-between" align="center">
        <v-card-subtitle><h3>{{ $t('comments.title') }}</h3></v-card-subtitle>
        <v-btn v-if="!readonly" class="techno-green" @click="addComment()">
          <v-icon class="mr-2">mdi-message-plus</v-icon>
          {{ $t('comments.add') }}
        </v-btn>
      </v-row>
      <div class="mt-2">
        <v-list v-for="comment of comments" v-bind:key="comment.id">
          <div class="comment-container">
            <div class="comment-header">
              <div class="comment-by">
                {{ comment.user.firstname }} {{ comment.user.lastname }}
              </div>
              <div class="comment-date-btns">
                <span v-if="deletingId == comment.id">
                  {{ $t('comments.comfirm_delete') }}
                  <v-icon class="ml-1 mr-1" @click="deleteComment(comment)">mdi-check</v-icon>
                  <v-icon class="ml-1 mr-1" @click="deletingId = null">mdi-close</v-icon>
                </span>
                <span v-else>
                  {{ moment(comment.updatedAt).format("YYYY/MM/DD HH:mm:ss") }}
                  <v-icon class="ml-1 mr-1" @click="editingId = comment.id; deletingId = null">mdi-pencil</v-icon>
                  <v-icon class="ml-1 mr-1" color="red" @click="deletingId = comment.id; editingId = null">mdi-delete</v-icon>
                </span>
              </div>
            </div>
            <div v-if="editingId == comment.id">
              <input 
                style="width: 100%;border: 1px solid lightgrey;padding-left: 3px;"
                :value="comment.comment"
                @input="commentChanged(comment, $event.target.value)"
                v-on:blur="editingId = null; deletingId = null"
                v-on:keyup.enter="editingId = null; deletingId = null"
                ref="currentEditing"
              >
            </div>
            <div v-else class="comment">
              <span v-on:click="editingId = comment.id; deletingId = null">{{ comment.comment }}</span>
            </div>
          </div>
        </v-list>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserService from "@/services/user.service";
import moment from 'moment';
import { createTimeout } from "@/utils/utils.js";

export default {
  props: ['relatableType', 'relatableId', 'readonly'],
  components: {
  },
  watch: {
    relatableId: {
      immediate: true,
      handler() { //Takes newValue, oldValue but we don't need them
        this.reloadPage();
      }
    }
  },
  data() {
    return {
      comments: null,
      editingId: null,
      deletingId: null,
      pendingUpdateTimers: {} //need to trigger them earlier when a comment is added and the update is not saved yet
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer
    }),
  }, 
  methods: {
    async reloadPage() {
      if (this.relatableType == null ||  this.relatableId == null) return;
      let data = await UserService.getComments(this.relatableType, this.relatableId);
      this.comments = data && data.data ? data.data : [];
    },

    //Need to save pending changes before other changes to prevent graphical weirdnesses
    async applyPendingChangesNow(exceptId) {
      for (let id in this.pendingUpdateTimers) {
        if (id == exceptId) continue;
        let timer = this.pendingUpdateTimers[id];
        if (timer != null) {
          await timer.trigger(true);
        }
      }
    },

    async addComment() {
      this.deletingId = null;
      await this.applyPendingChangesNow();
      let result = await UserService.addComment(this.relatableType, this.relatableId);
      if (result && result.data) this.editingId = result.data.id;
      await this.reloadPage();
      this.$nextTick(() => {
        this.$refs.currentEditing[0].focus();
      });
    },
    async commentChanged(comment, newValue) {
      this.deletingId = null;
      await this.applyPendingChangesNow(comment.id);
      let timer = this.pendingUpdateTimers[comment.id];
      if (timer != null) timer.clear();
      this.pendingUpdateTimers[comment.id] = createTimeout(async (noreload) => {
        delete this.pendingUpdateTimers[comment.id];
        await UserService.updateComment(comment.id, newValue);
        if (noreload !== true) this.reloadPage();
      }, 2000);
      comment.comment = newValue;
    },
    async deleteComment(comment) {
      await this.applyPendingChangesNow();
      await UserService.deleteComment(comment.id);
      this.reloadPage();
    },
    moment() {
      return moment.apply(this, arguments);
    },
  }
};
</script>

<style scoped>
.commentlist-content {
  width: 100%;
}

.comment-container {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
}

.comment-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.comment-by {
  font-weight: bold;
  font-size: 0.75em;
}
.comment-date-btns {
  font-size: 0.75em;
}
.comment {
  font-size: 0.8em;
}

</style>
