<template>
  <div id="project-file-list" class="projectfilelist-content" @dragenter.prevent="dragging++" @dragleave.prevent="dragging--" @drop.prevent="dropFile($event)">
    <div v-if="dragging" class="drag-icon">
      <div class="hover bounce">
        <v-icon class="mr-2">mdi-cloud-upload</v-icon>
      </div>
    </div>
    <div v-if="uploading" class="drag-icon">
      <div class="hover">
        <v-icon class="mr-2">mdi-loading mdi-spin mdi-48px</v-icon>
      </div>
    </div>
    <div :disabled="dragging || uploading" :class="dragging > 0 || uploading? ' dragging' : ''">
      <v-card class="border10 ml-3 mr-3 pl-3">
        <v-row justify="space-between" align="center">
          <v-card-subtitle><h3>{{$t('project.files')}}</h3></v-card-subtitle>
          <v-btn v-show="!readonly" class="techno-green mr-5" v-on:click="$refs.fileUpload.click();">
            <v-icon class="mr-2">mdi-cloud-upload</v-icon>
            {{ $t('project.add_file') }}
          </v-btn>
          <input style="display:none;" type="file" name="file" multiple ref="fileUpload" v-on:change="handleFileUpload"/>
        </v-row>
        <div class="request-container" v-if="files.length < 1">
          <div class="requests-anchor">
              <img class="required-img">
              <span class="wrappedfile">{{ $t('project.drop_file') }}</span>
          </div>
        </div>
        <v-list>
          <v-list-item v-for="file of files" v-bind:key="file.id">
            <v-list-item-content>
              <v-list-item-title>
                <div target="_blank" class="requests-anchor" @click="file.isImage ? thumbnailClicked(file) : downloadFile(file)" style="cursor:pointer;">
                  <span class="fileLine">
                    <img v-if="file.isImage && file.url?.thumbnail != null" :src="file.url.thumbnail">
                    <img v-else src="/assets/icon/doc-file.svg" class="mt-0 doc-icon">
                    <span class="fileInfo">
                      <span v-if="file.renaming" class="editingFile">
                        <v-text-field :id="file.inputId" v-model="file.filename" @blur="renameFile(file, 'disable')" @keyup.native.enter="renameFile(file, 'disable')"></v-text-field>
                      </span>
                      <span v-else-if="file.isImage" class="wrappedfile">{{ file.filename }}</span>
                      <span v-else class="wrappedfile">{{ file.filename }}.{{ file.extension }}</span>
                      
                      <span v-if="file.relatableType == 'document_requests' && file.documentTypeEnum != null" class="parentType">{{ $t(`${DOCUMENT_REQUEST_TYPES_TEXT[file.documentTypeEnum]}`) + ` #${file.relatableId}` }}</span>
                      <span v-else class="parentType">
                          <span v-if="file.requestType">{{ $t(`${REQUEST_TYPES_TEXT[file.requestType]}`)}}</span>
                          <span v-else>
                            {{ $t(`project.files_parent.${file.relatableType}`)}}
                          </span>
                          &nbsp;{{ `#${file.relatableId}` }}
                      </span>
                    </span>
                  </span>
                </div>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon v-if="!file.renaming">
              <v-icon v-if="file.relatableType == 'projects'" @click="deleteClicked(file)">mdi-delete</v-icon>
              <v-icon v-else-if="file.relatableType == 'site_reports'" @click="editClicked(file)">mdi-pencil</v-icon>
              <v-icon v-on:click="renameFile(file, 'enable')" :title="$t('reportInfo.rename_file')">mdi-form-textbox</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </div>

    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('confirm.head') }}
        </v-card-title>
        <v-card-text>{{ $t('confirm.file_message') }}</v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="confirmDialog = false">{{ confirmYesNo ? $t("reportInfo.no") : $t("confirm.cancel") }}</v-btn>
          <v-btn color="green darken-1" text @click="confirmDialogYes()">{{ confirmYesNo ? $t("reportInfo.yes") : $t("confirm.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="photosDialog" width="unset" content-class="photosDialog">
      <v-carousel height="auto" v-model="selectedPhotoIndex">
        <v-carousel-item v-for="(photo, i) in photos" :key="i">
          <v-sheet height="100%" tile style="max-height:85vh;">
            <img :src="photo.url.image" style="max-height:85vh;">
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>

    <v-dialog v-model="viewPdf.show" width="97%" height="100%">
      <v-card>
        <v-card-actions>
          {{viewPdf.title || ''}}
          <v-spacer></v-spacer>
          <v-icon @click="() => { viewPdf.show = false; viewPdf.data = null;}">mdi-close</v-icon>
        </v-card-actions>
        <v-card-text>
          <vue-pdf-app style="height: 83vh; display: block;" :pdf="viewPdf.data" page-scale="page-fit" theme.sync="light" :fileName="viewPdf.title + '.pdf'" :config="{ toolbar: { toolbarViewerRight: {openFile: false, viewBookmark: false} }, secondaryToolbar: false }"></vue-pdf-app>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="viewPdf.show && viewPdf.data == null" class="downloadingPdf">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

  </div>
</template>

<script>

import { mapState, mapMutations } from "vuex";
import FileService from "@/services/file.service";
import { DOCUMENT_REQUEST_TYPES_TEXT, REQUEST_TYPES_TEXT } from "@/enums";
import axios from "axios";
import authHeader from "@/services/auth-header";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  props: ['files', 'projectId', 'listUpdated', 'readonly'],
  components: {
    VuePdfApp
  },
  data() {
    return {
      DOCUMENT_REQUEST_TYPES_TEXT: DOCUMENT_REQUEST_TYPES_TEXT,
      REQUEST_TYPES_TEXT: REQUEST_TYPES_TEXT,
      selectedPhotoIndex: null,
      photosDialog: false,
      uploading: false,
      confirmDialog: false,
      confirmYesNo: true,
      fileToDelete: null,
      dragging: 0,
      isRenamingAFile: false,
      viewPdf: { show: false, title: null, data: null}
    }
  },
  async mounted() {
    console.log('Project file list mounted');
    window.addEventListener("dragover",function(e){
      e.preventDefault();
    },false);
    window.addEventListener("drop",function(e){
      e.preventDefault();
    },false);
  },
  created() {
     //console.log('Report created')
  },
  updated() {
    //console.log('Report updated')
  },
  beforeDestroy() {
    console.log('Project file list unmounted')
    //document.querySelectorAll(".pac-container").forEach(e => { console.log(e); e.remove(); });
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    photos() {
      let pictures = [];
      if (this.files && this.files.length > 0) {
        for (let c of this.files) {
          if (c.isImage) {
            c.index = pictures.length;
            pictures.push(c);
          }
        }
      }
      return pictures;
    }
  },
  methods: {
    ...mapMutations([
      'openToast'
    ]),
    log: console.warn,
    thumbnailClicked(file) {
      if (file.renaming) return;
      this.selectedPhotoIndex = file.index;
      this.photosDialog = true;
    },
    async downloadFile(file) {
      if (file.renaming) return;
      if (file.extension == 'pdf') {
        this.viewPdf.show = true;
        this.viewPdf.title = file.filename;
        try {
          let result = await axios.get(file.url.download, { headers: authHeader(), responseType: 'blob' });
          this.viewPdf.data = await result.data.arrayBuffer();
        } catch (error) {
          console.log(error);
          this.viewPdf.show = false;
          this.openToast({ message: error, duration: 5000, color: 'red'});
        }
      }
      else {
        window.open(file.url.download, '_blank');
      }
    },
    selectFileName(file) {
      let el = document.querySelector('#' + file.inputId);
      if (!el) return setTimeout(() => this.selectFileName(file), 100);
      el.focus();
      el.select();
    },
    async renameFile(file, action) {
      if (action == 'enable') {
        if (!isNaN(this.isRenamingAFile?.id) && this.isRenamingAFile.id != file.id) this.renameFile(this.isRenamingAFile, 'disable');
        file.renaming = true;
        this.isRenamingAFile = file;
        file.inputId = 'ren' + Date.now();
        setTimeout(() => this.selectFileName(file), 100);
      }
      else { //disable
        this.isRenamingAFile = false;
        delete file.renaming;
        delete file.inputId;
        FileService.renameFile(file);
      }
      this.$forceUpdate();
    },

    async confirmDialogYes() {
      this.confirmDialog = false;
      let result = await FileService.deleteFile(this.fileToDelete);
      if (!result.success) this.openToast({ message: result.data, duration: 5000, color: 'red'});
      else this.listUpdated();
    },

    async deleteClicked(file) {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.file_message');
      this.confirmYesNo = true;
      this.fileToDelete = file;
    },
    editClicked(file) {
      if (file.relatableType != 'site_reports') return; //not supposed to reach here otherwise
      this.$router.push('/report/' + file.relatableId)
    },
    async handleFileUpload() {
      this.uploading = true;
      for (let file of  this.$refs.fileUpload.files) {
        await this.uploadFile(file)
      }
      this.$refs.fileUpload.value = '';
      this.uploading = false;
      this.listUpdated();
    },

    async uploadFile(file) {
      let resp = await FileService.addNewFile(file, 'projects', this.projectId);
      if (resp.data?.alreadyExists === true) this.openToast({ message: this.$t('reportInfo.file_already_exists'), duration: 3000});
      else if (!resp.success) this.openToast({ message: resp.data, duration: 5000, color: 'red'});
      else this.$forceUpdate();
    },

    async dropFile(e) {
      this.dragging = 0;
      let files = Array.from(e.dataTransfer.files);
      this.uploading = true;
      for (let f of files) await this.uploadFile(f);
      this.uploading = false;
      this.listUpdated();
    }
  }
};
</script>

<style scoped>
.projectfilelist-content {
  width: 100%;
}

.wrappedfile {
  text-overflow: ellipsis !important;
  margin-left: 10px;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  line-height: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
}

.parentType {
  color: lightgrey;
  display: flex;
  position: absolute;
  vertical-align: bottom;
  font-size: small;
  margin-left: 10px;
  margin-top: 15px;
}

.fileInfo {
  transform: translateY(-12%);
}

.fileLine {
  display: flex;
}

.doc-icon {
  margin-left: 0;
  width: 48px;
  height: 48px;
}

.dragging {
  /*border: 2px dashed green;*/
  cursor: copy;
  pointer-events: none;
  z-index: 2;
}

.dragging div {
  cursor: copy;
  pointer-events: none;
  opacity: 0.5;
}

.drag-icon {
  width: 100%;
  height: 100%;
  margin-left: 50%;
}

.drag-icon div {
  cursor: copy;
  pointer-events: none;
  margin: auto;
}

.hover {
  cursor: copy;
  pointer-events: none;
	position: absolute;
  zoom: 1.5;
  margin-top: 15px !important;
}

.bounce {
	animation: bounce 2s infinite;
	-webkit-animation: bounce 2s infinite;
	-moz-animation: bounce 2s infinite;
	-o-animation: bounce 2s infinite;
}

.requests-anchor {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  display: block;
  height: 48px;
}
.requests-anchor img {
  height: 48px;
}
.requests-anchor ion-thumbnail {
  display: inline-block;
  height: 100%;
}
.request-container {
  position: relative;
  margin-top: 15px;
  /* width: 80%; */
}
.required-img{
  width:48px;
  height:48px;
  border:1px dashed black; 
  border-radius:8px;
}
.editingFile {
  padding-left: 10px;
  display: block;
}
 
@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-20px);}
	60% {-webkit-transform: translateY(-15px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-20px);}
	60% {-moz-transform: translateY(-15px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-20px);}
	60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-20px);}
	60% {transform: translateY(-15px);}
}

</style>
